let btnAuths = [
  {
    label: '详情',
    value: 'query',
  },
  {
    label: '编辑',
    value: 'update',
  },
  {
    label: '删除',
    value: 'delete',
  },
  {
    label: '新增',
    value: 'add',
  },
  {
    label: '导出',
    value: 'export',
  },
  {
    label: '导入',
    value: 'import',
  },
  {
    label: '批量续存',
    value: 'batchRenew',
  },
  {
    label: '下载模版',
    value: 'downloadTemplate',
  },
  {
    label: '批量下发',
    value: 'batchDelivery',
  },
  {
    label: '重置密码',
    value: 'resetPwd',
  },
  {
    label: '上报状态',
    value: 'uploadState',
  },
  {
    label: '批量上报状态',
    value: 'batchUploadState',
  },
  {
    label: '同步房台',
    value: 'syncRoom',
  },
  {
    label: '执行开台',
    value: 'executeOpenRoom',
  },
  {
    label: '执行关台',
    value: 'executeCloseRoom',
  },
  {
    label: '上架',
    value: 'goRack',
  },
  {
    label: '下架',
    value: 'downRack',
  },
  {
    label: '结算信息',
    value: 'billMsg',
  },
  {
    label: '发布',
    value: 'release',
  },
  {
    label: '重新计算',
    value: 'recalculate',
  },
  {
    label: '缴费登记',
    value: 'payment',
  },
  {
    label: '配置管理',
    value: 'configuration',
  },
  {
    label: '解绑',
    value: 'untie',
  },
  {
    label: '设置主收银',
    value: 'updateCashier',
  },
  {
    label: '注册',
    value: 'register',
  },
  {
    label: '总部发劵',
    value: 'headCoupon',
  },
  {
    label: '门店发劵',
    value: 'storeCoupon',
  },
  {
    label: '群二维码',
    value: 'qrcode',
  },
  {
    label: '指定门店',
    value: 'designatedStore',
  },
  {
    label: '全部门店',
    value: 'allStores',
  },
]

//详情  新增 编辑  删除 导入  导出
const operationAuth = (route, authMenus) => {
  let auths = route.meta.msg.childrens
  authMenus.msg = auths
  btnAuths.forEach(item => {
    authMenus[item.value] = auths.find(a => a.menuName == item.label) ? true : false
  })
}

export default operationAuth
